import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";

// Register the necessary chart elements with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

const DualAxisChartComponent = ({ machineProductionData }) => {
  const [chartData, setChartData] = useState({
    labels: "",
    datasets: [],
  });

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Production KG and Ribbon Used against Machines", // Chart title
      },
      legend: {
        position: "top", // Position of the legend
      },
    },
    scales: {
      "y-axis-left": {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Production KG", // Left axis label
        },
      },
      "y-axis-right": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: "Ribbon Used", // Right axis label
        },
      },
    },
  };

  // console.log("ribbon", machineProductionData.ribbon);

  useEffect(() => {
    console.log(machineProductionData.machineNames);
    console.log(machineProductionData.productionKG);
    console.log(machineProductionData.ribbons);

    setChartData({
      labels: machineProductionData.machineNames,
      datasets: [
        {
          label: "Production KG",
          data: machineProductionData.productionKG, // Y-axis data for the left axis (Bar chart)
          backgroundColor: "rgba(230, 113, 19, 0.8)", // Color for the Production KG bars
          yAxisID: "y-axis-left", // Left y-axis
          type: "bar", // Set chart type to bar
        },
        {
          label: "Ribbon Used",
          data: machineProductionData.ribbons,
          borderColor: "rgb(54, 162, 235)", // Color for the line
          borderWidth: 2,
          // fill: false, // Don't fill the line area
          yAxisID: "y-axis-right", // Right y-axis
          type: "line", // Set chart type to line
        },
      ],
    });
  }, [machineProductionData]);

  return <Bar data={chartData} options={chartOptions} height={100} />;
};

export default DualAxisChartComponent;
