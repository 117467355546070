import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import PieChart from "../../../Component/PieChart/PieChart";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Mybutton from "../../../Component/Mybutton/Mybutton";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../Utils/constants";
import LineChart from "../../../Component/LineChart/LineChart";
import StackedBarChart from "../../../Component/StackedBarChart/StackedBarChart";
import { Bar } from "react-chartjs-2";
import ScatterChart from "../../../Component/Scatter/ScatterChart";
import HorizontalBarChart from "../../../Component/HorizontalBarChart/HorizontalBarChart";
import DualAxisChartComponent from "../../../Component/DualAxisChart/DualAxisChartComponent";
// import DualAxisChartComponent from "../../../Component/DualAxisChart/DualAxisChartComponent";

function Graphs() {
  // for notification
  const notify = (notification, type) =>
    toast(notification, { autoClose: 2000, theme: "colored", type: type });

  const navigate = useNavigate();

  // States
  const [machineProductionData, setMachineProductionData] = useState({
    productionKG: [],
    machineNames: [],
  });

  const [machineProductionwithRibbon, setmachineProductionwithRibbon] =
    useState({
      productionKG: [],
      machineNames: [],
      ribbon: [],
    });
  const [skuProductionData, setSkuProductionData] = useState({
    productionKG: [],
    skuNames: [],
  });
  const [graphColors] = useState([
    // "rgba(255, 99, 132, 0.6)", // Red
    "rgba(54, 162, 235, 0.6)", // Blue
    // "rgba(255, 206, 86, 0.6)", // Yellow
    // "rgba(75, 192, 192, 0.6)", // Green
    // "rgba(153, 102, 255, 0.6)", // Purple
    // "rgba(255, 159, 64, 0.6)", // Orange
    // "rgba(199, 199, 199, 0.6)", // Grey
    // "rgba(83, 102, 255, 0.6)", // Dark Blue
    // "rgba(255, 0, 255, 0.6)", // Magenta
    // "rgba(0, 255, 255, 0.6)", // Cyan
  ]);

  const today = new Date();
  const start_Date = new Date(today.setMonth(today.getMonth() - 1))
    .toISOString()
    .split("T")[0];
  const end_Date = new Date().toISOString().split("T")[0];

  const [filterDate, setFilterDate] = useState({
    startDate: start_Date,
    endDate: end_Date,
  });

  const [machineTarget, setMachineTarget] = useState([]);
  const [uniqueDataSet, setUniqueDataSet] = useState(null);

  const [stackedBarData, setstackedBarData] = useState(null);

  // URLs
  const machineTargetUrl = BASE_URL + "/api/machinetarget";
  const getMachineProduction_url = BASE_URL + "api/machine-productionkg/";
  const getSKUProduction_url = BASE_URL + "api/sku-productionkg/";
  const getStackedBar_url = BASE_URL + "api/machinegrap";
  const getribbonUsed_url = BASE_URL + "api/ribbon-production/";

  const fetchStackedBar = async () => {
    try {
      const response = await axios.get(getStackedBar_url, {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      });

      if (response?.status === 200) {
        setstackedBarData(response?.data?.data);
      }
    } catch (error) {
      console.error("error fetching data stacked", error);
    }
  };

  useEffect(() => {
    fetchStackedBar();
  }, []);

  // Get machine production data
  const handleMachineProduction = async (startDate, endDate) => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    try {
      const res = await axios.get(getMachineProduction_url, {
        headers: header,
        params: {
          start_date: startDate || "",
          end_date: endDate || "",
        },
      });
      if (res.data.status) {
        const machineNames = res.data.data.map((item) => item.machine_name);
        const productionKGs = res.data.data.map(
          (item) => item.production_kg_sum
        );

        setMachineProductionData({
          machineNames,
          productionKG: productionKGs,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        notify("Unauthorized user", "error");
        navigate("/");
      }
    }
  };

  const handleMachineProductionWithRibbon = async (startDate, endDate) => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    try {
      const res = await axios.get(getribbonUsed_url, {
        headers: header,
        params: {
          start_date: startDate || "",
          end_date: endDate || "",
        },
      });
      if (res.data.status) {
        const machineNames = res.data.agg_data.map((item) => item.machine_name);
        const productionKGs = res.data.agg_data.map(
          (item) => item.production_kg_sum
        );
        const ribbonUsed = res.data.agg_data.map(
          (item) => item.agg_ribbon_used
        );
        console.log("ribbonUsed", ribbonUsed);

        setmachineProductionwithRibbon({
          machineNames,
          productionKG: productionKGs,
          ribbon: ribbonUsed,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        notify("Unauthorized user", "error");
        navigate("/");
      }
    }
  };

  // Get SKU production data
  const handleSKUProduction = async (startDate, endDate) => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    try {
      const res = await axios.get(getSKUProduction_url, {
        headers: header,
        params: {
          start_date: startDate || "",
          end_date: endDate || "",
        },
      });
      if (res.data.status) {
        const skuNames = new Set(
          res.data.data.map((item) => item.skudata__product_name)
        );
        const productionKGs = res.data.data.map((item) => item.production_Kg);
        setSkuProductionData({
          skuNames: Array.from(skuNames),
          productionKG: productionKGs,
        });
      }
    } catch (err) {
      console.log(err);
      if (err.response?.status === 401) {
        notify("Unauthorized user", "error");
        navigate("/");
      }
    }
  };

  const handleReset = () => {
    setFilterDate({
      startDate: "",
      endDate: "",
    });
    handleMachineProduction();
    handleSKUProduction();
    handleMachineProductionWithRibbon();
  };

  useEffect(() => {
    handleMachineProduction();
    handleSKUProduction();
    handleMachineProductionWithRibbon();
  }, []);

  return (
    <>
      <div className="row text-center">
        <div className="col-12">
          <div
            className="row"
            style={{
              overflow: "auto",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <div className="col-lg-4 p-2">
              <FloatingLabel controlId="floatingInput" label="Start Date">
                <Form.Control
                  style={{ border: "1px solid grey", borderRadius: "5px" }}
                  type="date"
                  placeholder="Start Date"
                  value={filterDate.startDate}
                  onChange={(e) => {
                    setFilterDate((prev) => ({
                      ...prev,
                      startDate: e.target.value,
                    }));
                  }}
                />
              </FloatingLabel>
            </div>
            <div className="col-lg-4 p-2">
              <FloatingLabel controlId="floatingInput" label="End Date">
                <Form.Control
                  style={{ border: "1px solid grey", borderRadius: "5px" }}
                  value={filterDate.endDate}
                  onChange={(e) => {
                    setFilterDate((prev) => ({
                      ...prev,
                      endDate: e.target.value,
                    }));
                  }}
                  type="date"
                  placeholder="End Date"
                />
              </FloatingLabel>
            </div>
            <div className="d-flex justify-content-center gap-2 p-2">
              <Mybutton
                // backgroundColor={"blue"}
                color={"#fff"}
                name={"apply"}
                handleClick={() => {
                  if (
                    filterDate.startDate.length &&
                    filterDate.endDate.length
                  ) {
                    handleMachineProduction(
                      filterDate.startDate,
                      filterDate.endDate
                    );
                    handleSKUProduction(
                      filterDate.startDate,
                      filterDate.endDate
                    );
                    handleMachineProductionWithRibbon(
                      filterDate.startDate,
                      filterDate.endDate
                    );
                  }
                }}
              />
              <Mybutton
                // backgroundColor={"#fb5e5e"}
                backgroundColor={"#782121"}
                color={"#fff"}
                name={"Reset"}
                handleClick={handleReset}
              />
            </div>
          </div>
        </div>
      </div>

      {/* pie chart data */}
      <div className="row gap-1 ">
        {/* <div className="col-1"></div>
        <div
          className="col-lg-5  shadow-sm  mb-2"
          style={{ border: "1px solid grey", borderRadius: "5px" }}
        >
          <div className="m-2">
            <strong className="">Production KG against Machines</strong>
          </div>
          <PieChart
            heading={"Production KG against Machines"}
            productionKGData={machineProductionData.productionKG}
            labels={machineProductionData.machineNames}
            labelName={"Production KG"}
            colors={graphColors}
            legendLabel="Machine Name"
          />
        </div> */}

        {/* <div
          className="col-lg-5  shadow-sm  mb-2"
          style={{ border: "1px solid grey", borderRadius: "5px" }}
        >
          <div className="m-2 ">
            <strong>Production KG against SKU</strong>
          </div>
          <PieChart
            heading={"Production KG against SKU"}
            productionKGData={skuProductionData.productionKG}
            labels={skuProductionData.skuNames}
            labelName={"Production KG"}
            colors={graphColors}
            legendLabel="SKU Name"
          />
        </div>
        <div className="col-1"></div> */}
      </div>
      {/* end pie */}

      <div
        className=""
        style={{
          // border: "1px solid red",
          border: "1px solid grey",
          borderRadius: "5px",
          margin: "24px",
        }}
      >
        {/* Horizontal Bar Chart */}
        <HorizontalBarChart
          heading="Production KG against Machines"
          productionKGData={machineProductionData.productionKG}
          labels={machineProductionData.machineNames}
          labelName="Production KG"
          colors={graphColors}
          legendLabel="Machine Name"
          chartHeight={100}
        />
      </div>

      <div
        style={{
          border: "1px solid grey",
          borderRadius: "5px",
          margin: "24px",
        }}
      >
        <DualAxisChartComponent
          machineProductionData={{
            productionKG: machineProductionwithRibbon.productionKG,
            machineNames: machineProductionwithRibbon.machineNames,
            ribbons: machineProductionwithRibbon.ribbon, // Make sure to include the other metric
          }}
          // graphColors={graphColors}
          graphColors={["#FF5733", "#33FF57"]} // Example colors for the bars and line
          otherMetricColor="#FF00FF"
        />
      </div>

      <div
        style={{
          border: "1px solid grey",
          borderRadius: "5px",
          margin: "24px",
        }}
      >
        <HorizontalBarChart
          heading={"Production KG against SKU"}
          productionKGData={skuProductionData.productionKG}
          labels={skuProductionData.skuNames}
          labelName={"Production KG"}
          colors={graphColors}
          legendLabel="SKU Name"
          chartHeight={150}
        />
      </div>

      <div className="row ">
        <div className="col-1"></div>
        <div
          className="col-lg-10 "
          // style={{ border: "1px solid grey", borderRadius: "5px" }}
        >
          {stackedBarData !== null ? (
            <ScatterChart data={stackedBarData} />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
}

export default Graphs;
