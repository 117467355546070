import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "../../../Component/Loader/CustomLoader";
import { BASE_URL } from "../../../Utils/constants";
import "./PlantDetails.css";

function PlantDetails() {
  // for notification
  const notify = (notification, type) =>
    toast(notification, { autoClose: 2000, theme: "colored", type: type });

  const navigate = useNavigate();

  const [plantData, setPlantData] = useState([]);
  const [loader, setLoader] = useState(false);

  //url
  const getPlantDetails_url = BASE_URL + "api/getplant/";

  const getPlantDetails = async () => {
    setLoader(true);

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getPlantDetails_url, { headers: header })
      .then((res) => {
        if (res.data.status) {
          setPlantData(res?.data?.data);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          notify("Unathorized user", "error");
          navigate("/");
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    getPlantDetails();
  }, []);

  return (
    <>
      {loader ? (
        <CustomLoader />
      ) : (
        <div className="p-lg-3 p-2">
          <div className="card card_main">
            <div
              className="card-body"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              <Table bordered responsive className="table">
                <thead>
                  <tr>
                    <th className="table_head text-dark ">Plant ID</th>
                    <th className="table_head text-dark"> Name</th>
                    <th className="table_head text-dark">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {plantData?.length ? (
                    plantData.map((item) => {
                      return (
                        <tr
                          className="table_row"
                          key={item.plantID + item.name}
                        >
                          <td
                            className="table_data"
                            style={{ fontWeight: "bold" }}
                          >
                            {item.plantID}
                          </td>
                          <td className="table_data">{item.name}</td>
                          <td className="table_data">{item.location}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-danger text-center">
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlantDetails;
