import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Table,
} from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import toast, { Toaster } from "react-hot-toast";
import Mybutton from "../../../Component/Mybutton/Mybutton";
import axios from "axios";
import { BASE_URL } from "../../../Utils/constants";
import * as XLSX from "xlsx";
import "./componentpo.css";
import CustomLoader from "../../../Component/Loader/CustomLoader";

function ComponentPo() {
  const [compoId, setcompoId] = useState([]);
  const [selcted_compItem, setSelectedCompItem] = useState("");
  const [selcted_compValue, setSelect_compValue] = useState("");
  const [tableData, settableData] = useState([]);
  const [search, setsearch] = useState("");
  const [loader, setLoader] = useState(true);

  const [ponumbers, setPoNumbers] = useState([]); // All PO data
  // console.log("ponumbers", ponumbers);

  const [selectedpo, setSelectedPo] = useState(""); // Selected PO number
  const [podata, setPoData] = useState([]); // Data for the selected PO number
  const [activeRowIndex, setActiveRowIndex] = useState(-1); // Data for the selected PO number
  // console.log("podata", podata);
  const [searchpo, setSearchPo] = useState("");

  const [data, setData] = useState([]);
  // console.log("submittedData", submittedData);

  //Pagination Codes
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  const [totalPages, setTotalPages] = useState(1);

  //urls
  const getComponent_url = BASE_URL + "billmaterial/ComponentAPIView";
  const postComponent_url = BASE_URL + "billmaterial/componenetePO/";
  const getComponentTable_url = BASE_URL + "billmaterial/componenetePO/";
  const exportall_url = BASE_URL + "billmaterial/ComponentPOListAPIView/";
  const ponumber_url = BASE_URL + "billmaterial/vendorcomponent/"; //all po number data
  // const ponumber_url = BASE_URL + "/billmaterial/vendorcomponeadd/";
  const postData_url = BASE_URL + "billmaterial/vendorcomponeadd/";
  const getDataByPo = BASE_URL + "billmaterial/VendorecomponentAllView/";

  //for post data
  const [formData, setFormData] = useState({
    po_number: "",
    taxinvoiceNo: "",
    taxinvoice_quantity: "",
    received_quantity: "",
    po_quantity: "",
  });

  const [filterDates, setfilterDates] = useState({
    startDate: "",
    endDate: "",
  });

  // for select option kg,gm
  const unitOptions = [
    { value: "gm", label: "gm" },
    { value: "kg", label: "kg" },
    { value: "nos", label: "nos" },
    { value: "mm", label: "mm" },
    { value: "m", label: "m" },
  ];

  const handleSelect = (selct_item) => {
    setSelectedCompItem(selct_item.item);
    setSelect_compValue(selct_item.item_code);
  };

  //state management for form fields
  const [componentformData, setcomponentformData] = useState({
    new_ponumber: "",
    new_podate: "",
    new_taxinvoice: "",
    new_receivedquantity: "",
    new_locate: "",
    new_poquantity: "",
    new_rates: "",
    new_units: "",
  });

  const handleUnitChange = (e) => {
    const { value } = e.target;
    setcomponentformData((prevData) => ({
      ...prevData,
      new_units: value,
    }));
  };

  // post data
  const handleSubmit = async () => {
    if (
      !selcted_compValue ||
      !componentformData.new_ponumber ||
      !componentformData.new_podate ||
      !componentformData.new_taxinvoice ||
      !componentformData.new_receivedquantity ||
      !componentformData.new_locate ||
      !componentformData.new_poquantity ||
      !componentformData.new_rates ||
      !componentformData.new_units
    ) {
      toast.error("All fields are required");
      return;
    }

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const data = new FormData();
    const receivedQuantity = parseInt(componentformData.new_receivedquantity);
    const rate = parseInt(componentformData.new_rates);
    const basic_rate = receivedQuantity * rate;
    const total_gst = basic_rate * 0.18;
    const total_amount = basic_rate + total_gst;
    data.append("newpo_number", componentformData.new_ponumber);
    data.append("newpo_date", componentformData.new_podate);
    data.append("newtaxinvoiceNo", componentformData.new_taxinvoice);
    data.append("newreceived_quantity", componentformData.new_receivedquantity);
    data.append("newlocation", componentformData.new_locate);
    data.append("newpo_quantity", componentformData.new_poquantity);
    data.append("newrate", componentformData.new_rates);
    data.append("newunit", componentformData.new_units);
    data.append("newcomponent", selcted_compValue);

    data.append("basic_rate", basic_rate.toFixed(3));
    data.append("total_gst", total_gst.toFixed(3));
    data.append("total_ammount", total_amount.toFixed(3));

    await axios
      .post(postComponent_url, data, {
        headers: header,
      })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
          setcomponentformData({
            new_ponumber: "",
            new_podate: "",
            new_taxinvoice: "",
            new_receivedquantity: "",
            // new_taxinvoicequantity: "",
            new_locate: "",
            new_poquantity: "",
            new_rates: "",
            new_units: "",
          });
          setSelectedCompItem("");
          setSelect_compValue("");
          fetchTableData();
        }
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          toast.error("Unathorized user", "error");
        }
      });
  };

  const handleSearch = () => {
    if (filterDates.startDate && filterDates.endDate) {
      fetchTableData(filterDates.startDate, filterDates.endDate);
    }
  };

  // for dropdown
  const fetchData = async () => {
    await axios
      .get(getComponent_url)
      .then((res) => {
        if (res.data.status) {
          setcompoId(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchTableData = async (startDate, endDate) => {
    setLoader(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getComponentTable_url, {
        headers: header,
        params: {
          from_date: startDate || "",
          to_date: endDate || "",
          page: currentPage,
          page_size: pageSize,
        },
      })
      .then((res) => {
        if (res.data.status) {
          // settableData(res.data.data);
          const { data, pagination } = res.data;
          settableData(data);

          // newly added data for pagination

          if (pagination && pagination.length > 0) {
            const totalItems = pagination[0].total;
            const calculatedTotalPages = Math.ceil(totalItems / pageSize);
            setTotalPages(calculatedTotalPages);
          }

          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  // for filter table data as per the po_date
  const filteredAndSortedData = tableData
    .filter((item) => {
      const itemDate = new Date(item.newpo_date);
      const filterStartDate = new Date(filterDates.startDate);
      const filterEndDate = new Date(filterDates.endDate);

      return (
        (!filterDates.startDate || itemDate >= filterStartDate) &&
        (!filterDates.endDate || itemDate <= filterEndDate)
      );
    })
    .sort((a, b) => new Date(b.newpo_date) - new Date(a.newpo_date));

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setcomponentformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (id, name, value, index) => {
    var list = ["received_quantity", "taxinvoiceNo", "taxinvoice_quantity"];
    list = list.filter((e) => e !== name);

    setPoData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, [name]: value } : item
      )
    );

    var doit = list.reduce((d, f) => d && podata[index][f] === "", true);

    if (doit && value === "") {
      setActiveRowIndex(-1);
    } else {
      setActiveRowIndex(index);
    }
  };

  const handleSave = async () => {
    const updatedPodata = podata.map((item, index) => ({
      ...item,
      id: item.id || index + 1,
      // po_number: item.po_number || "Default PO",
      stock_log: item.stock_log || { balance: "N/A" },
    }));

    // console.log("Updated Payload:", updatedPodata);

    try {
      let header = {
        Authorization: `Token ${localStorage.getItem("token")}`,
      };

      await axios.post(postData_url, updatedPodata[activeRowIndex], {
        headers: header,
      });

      const response = await axios.get(
        getDataByPo,
        { params: { po_number: selectedpo } },
        {
          headers: header,
        }
      );
      // console.log("Data saved successfully in api:", response.data);

      // setSubmittedData(updatedPodata);

      // const savedData = {
      //   ...updatedPodata[0],
      //   stock_log: {
      //     ...updatedPodata[0].stock_log,
      //     balance: response.data.stock_log?.balance ?? "N/A",
      //   },
      // };
      // console.log("savedData ", savedData);
      // console.log("stocklog", response.data.stock_log?.balance);

      setData(response.data.data); // Append the saved data

      handleSelectPoNumber(selectedpo);
      setActiveRowIndex(-1);
      toast.success("Component PO updated successfully.");
      // setSubmittedData((prevData) => {
      //   const updatedData = [...prevData];

      //   updatedPodata.forEach((item) => {
      //     const index = updatedData.findIndex((data) => data.id === item.id);

      //     if (index > -1) {
      //       updatedData[index] = { ...updatedData[index], ...item };
      //       // updatedData[index] = { ...updatedData[index], ...savedData };
      //     } else {
      //       updatedData.push(item);
      //       // updatedData.push(savedData);
      //     }
      //   });
      //   console.log(" updated submitted data", updatedData);

      //   return updatedData;
      // });
      // console.log("submited print", submittedData);
    } catch (err) {
      toast.error("Something went wrong.");
      console.error("Error saving data:", err.response?.data || err.message);
    }
  };

  const fetchVendorComponentPoData = async () => {
    try {
      const response = await axios.get(ponumber_url);
      const uniquePoNumber = [
        ...new Set(response.data.data.map((item) => item.po_number)),
      ];
      const modifiedData = response.data.data
        .filter((item) => uniquePoNumber.includes(item.po_number))
        .map((item) => ({
          ...item,
          taxinvoiceNo: "",
          received_quantity: "",
          taxinvoice_quantity: "",
        }));
      setPoNumbers(modifiedData);
      // console.log("modifiedData", modifiedData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchVendorComponentPoData();
  }, []);

  const filterPoNumber = ponumbers.filter((poData) =>
    poData.po_number.toLowerCase().includes(searchpo.toLowerCase())
  );

  const handleSelectPoNumber = async (ponumber) => {
    setSelectedPo(ponumber);
    try {
      let header = {
        Authorization: `Token ${localStorage.getItem("token")}`,
      };

      var response = await axios.get(
        getDataByPo,
        { params: { po_number: ponumber } },
        {
          headers: header,
        }
      );

      setData(response.data.data);
      const selectedData = ponumbers.filter(
        (item) => item.po_number === ponumber
      );

      if (selectedData.length > 0) {
        const selectedPO = selectedData[0];

        setPoData(selectedData);

        setFormData({
          po_number: selectedPO.po_number || "",
          taxinvoiceNo: selectedPO.taxinvoiceNo || "",
          received_quantity: selectedPO.received_quantity || "",
          taxinvoice_quantity: selectedPO.taxinvoice_quantity || "",
          po_quantity: selectedPO.po_quantity || "",
        });
      } else {
        console.error("No data found for the selected PO number.");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const downloadExcel = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    try {
      const response = await axios.get(exportall_url, {
        headers: header,
        params: {},
      });

      const tableData = response.data.data;

      if (!Array.isArray(tableData)) {
        throw new Error("Expected an array for tableData");
      }

      const dataToExport = tableData.map((item) => ({
        "PO Number": item.newpo_number,
        "Created Date": item.newcreated_date,
        "PO Date": item.newpo_date,
        "Tax Invoice No": item.newtaxinvoiceNo,
        "Received Quantity": item.newreceived_quantity,
        Location: item.newlocation,
        "PO Quantity": item.newpo_quantity,
        Unit: item.newunit,
        Component: item.newcomponent,
        Rate: item.newrate,
        "Basic Rate": item.basic_rate,
        "Total GST (18%)": item.total_gst,
        "Total Amount": item.total_ammount,
      }));

      // Calculate totals
      const totals = tableData.reduce(
        (acc, item) => {
          acc.newreceived_quantity +=
            parseFloat(item.newreceived_quantity) || 0;
          acc.newrate += parseFloat(item.newrate) || 0;
          acc.basic_rate += parseFloat(item.basic_rate) || 0;
          acc.total_gst += parseFloat(item.total_gst) || 0;
          acc.total_amount += parseFloat(item.total_ammount) || 0;
          return acc;
        },
        {
          newreceived_quantity: 0,
          newrate: 0,
          basic_rate: 0,
          total_gst: 0,
          total_amount: 0,
        }
      );

      // Add totals row
      dataToExport.push({
        "PO Number": "Total",
        "Created Date": "",
        "PO Date": "",
        "Tax Invoice No": "",
        "Received Quantity": "",
        Location: "",
        "PO Quantity": "",
        Unit: "",
        Component: "",
        Rate: totals.newrate,
        "Basic Rate": totals.basic_rate,
        "Total GST (18%)": totals.total_gst,
        "Total Amount": totals.total_amount,
      });

      // Convert data to worksheet and save as Excel file
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "ComponentData");

      XLSX.writeFile(workbook, "ComponentData.xlsx");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Calculate totals
  const totals = tableData.reduce(
    (acc, item) => {
      acc.newreceived_quantity += parseFloat(item.newreceived_quantity) || 0;
      // acc.newtaxinvoice_quantity +=
      //   parseFloat(item.newtaxinvoice_quantity) || 0;
      acc.newrate += parseFloat(item.newrate) || 0;
      acc.basic_rate += parseFloat(item.basic_rate) || 0;
      acc.total_gst += parseFloat(item.total_gst) || 0;
      acc.total_amount += parseFloat(item.total_ammount) || 0;
      return acc;
    },
    {
      newreceived_quantity: 0,
      // newtaxinvoice_quantity: 0,
      newrate: 0,
      basic_rate: 0,
      total_gst: 0,
      total_amount: 0,
    }
  );

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [currentPage, filterDates]);

  const filterdata = filteredAndSortedData.filter(
    (itm) =>
      itm.newpo_number?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newcreated_date?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newpo_date?.toString().includes(search) ||
      itm.newtaxinvoiceNo?.toString().includes(search) ||
      itm.newreceived_quantity?.toString().includes(search) ||
      itm.newlocation?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newpo_quantity?.toString().includes(search) ||
      itm.newunit?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newcomponent?.toLowerCase().includes(search.toLowerCase()) ||
      itm.newrate.toString().includes(search) ||
      itm.basic_rate.toString().includes(search) ||
      itm.total_gst.toString().includes(search) ||
      itm.total_ammount.toString().includes(search)
  );
  // const finaltableData = async () => {
  //   try {
  //     const response = await axios.get(getresponseData_url);
  //     console.log("final data", response.data.data);
  //     setUpdatedTable(response.data.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   finaltableData();
  // }, []);

  return (
    <>
      <div>
        <Toaster />

        {loader ? (
          <CustomLoader />
        ) : (
          <>
            <div
              className="row p-2 m-3 main_class"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <InputGroup>
                  <DropdownButton
                    style={{ border: "1px solid grey" }}
                    variant="outline-secondary"
                    title="component"
                  >
                    {compoId.length
                      ? compoId.map((item) => {
                          return (
                            <Dropdown.Item
                              key={item?.item_code}
                              onClick={() => handleSelect(item)}
                            >
                              {item?.item}
                            </Dropdown.Item>
                          );
                        })
                      : ""}
                  </DropdownButton>
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    readOnly
                    aria-label="Text input with dropdown button"
                    value={
                      selcted_compItem ? selcted_compItem : "Select CompItem"
                    }
                  />
                </InputGroup>
              </div> */}
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                {/* <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    PO Number
                  </span>
                  <input
                    style={{ maxWidth: "100%", border: "1px solid grey" }}
                    value={componentformData.new_ponumber}
                    onChange={handleInputChange}
                    name="new_ponumber"
                    type="text"
                    placeholder="ponumber"
                    className="form-control"
                    aria-label="newponumber"
                    aria-describedby="basic-addon1"
                    // style={{ }}
                  />
                </div> */}
                {/* <InputGroup className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title={selectedpo || "select po number"}
                    id="input-group-dropdown-1"
                    onSelect={handleSelectPoNumber}
                  >
                    {ponumbers.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.poNumber}>
                        {item.po_number}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <Form.Control
                    aria-label="Text input with dropdown button"
                    value={selectedpo || "selected po number"}
                  />
                </InputGroup> */}
                <InputGroup className="mb-3">
                  <DropdownButton
                    variant="outline-secondary"
                    title={selectedpo || "Select PO Number"}
                    id="input-group-dropdown-1"
                    onSelect={handleSelectPoNumber}
                  >
                    <Dropdown.Header>
                      <Form.Control
                        placeholder="Search Po Number"
                        value={searchpo}
                        onChange={(e) => setSearchPo(e.target.value)}
                      />
                    </Dropdown.Header>
                    {/* {ponumbers.map((item, index) => (
                      <Dropdown.Item key={index} eventKey={item.po_number}>
                        {item.po_number}
                      </Dropdown.Item>
                    ))} */}

                    {
                      //     [
                      //   ...new Set(ponumbers.map((item) => item.po_number)), // Get unique PO numbers
                      // ]
                      [
                        ...new Set(
                          filterPoNumber.map((item) => item.po_number)
                        ), // Get unique PO numbers
                      ].map((poNumber) => {
                        // Find the item associated with the unique PO number
                        const poItem = ponumbers.find(
                          (item) => item.po_number === poNumber
                        );
                        return (
                          <Dropdown.Item
                            key={poItem.id}
                            eventKey={poItem.po_number}
                          >
                            {poItem.po_number}
                          </Dropdown.Item>
                        );
                      })
                    }
                  </DropdownButton>
                  <Form.Control
                    aria-label="Text input with dropdown button"
                    value={selectedpo || "Selected PO Number"}
                    readOnly
                  />
                </InputGroup>
              </div>
              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <InputGroup>
                  <InputGroup.Text
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    PO Date
                  </InputGroup.Text>
                  <Form.Control
                    style={{ border: "1px solid grey" }}
                    type="date"
                    name="new_podate"
                    value={componentformData.new_podate}
                    onChange={handleInputChange}
                    placeholder="YYYY-MM-DD"
                    aria-label="Created Quotation Date"
                  />
                </InputGroup>
              </div> */}
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                {/* <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Tax Invoice No.
                  </span>
                  <input
                    name="new_taxinvoice"
                    type="number"
                    placeholder="taxinvoice"
                    className="form-control"
                    aria-label="newtaxinvoiceNo"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%", border: "1px solid grey" }}
                    value={componentformData.new_taxinvoice}
                    onChange={handleInputChange}
                  />
                </div> */}
              </div>
              <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                {/* <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Received Qty
                  </span>
                  <input
                    name="new_receivedquantity"
                    type="number"
                    placeholder="received_quantity"
                    className="form-control"
                    aria-label="newreceived_quantity"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%", border: "1px solid grey" }}
                    value={componentformData.new_receivedquantity}
                    onChange={handleInputChange}
                  />
                </div> */}
              </div>

              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Location
                  </span>
                  <input
                    name="new_locate"
                    type="text"
                    placeholder="location"
                    className="form-control"
                    aria-label="newlocation"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%", border: "1px solid grey" }}
                    value={componentformData.new_locate}
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}
              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    PO Qty
                  </span>
                  <input
                    name="new_poquantity"
                    type="number"
                    placeholder="po_quantity"
                    className="form-control"
                    aria-label="newpo_quantity"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%", border: "1px solid grey" }}
                    value={componentformData.new_poquantity}
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}
              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Rate
                  </span>
                  <input
                    name="new_rates"
                    type="number"
                    placeholder="rate"
                    className="form-control"
                    aria-label="newrate"
                    aria-describedby="basic-addon1"
                    style={{ maxWidth: "100%", border: "1px solid grey" }}
                    value={componentformData.new_rates}
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}
              {/* <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{ minWidth: "35%", border: "1px solid grey" }}
                  >
                    Unit
                  </span>
                  <Form.Select
                    style={{ border: "1px solid grey" }}
                    aria-label="Default select example"
                    value={componentformData.new_units}
                    onChange={handleUnitChange}
                  >
                    <option>Select Unit</option>
                    {unitOptions.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </div> */}
              <div className="text-center p-2 mt-2">
                {/* <Mybutton name="Submit" handleClick={handleSubmit} /> */}
              </div>
            </div>

            {/* <div
              className="p-2 m-3 main_class"
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                backgroundColor: "whitesmoke",
              }}
            >
           
              <div className="d-lg-flex align-items-center justify-content-center">
                <div className="col-lg-6 d-flex justify-content-lg-end">
                  <h4 className="text-center px-2 justify-content-center align-items-center">
               
                  </h4>
                </div>
                <div className="col-lg-6 d-lg-flex justify-content-lg-end justify-content-center gap-2">
                  <div className="text-center me-2">
                    <Mybutton
                      name={"Export All"}
                      color={"#fff"}
                    
                      handleClick={downloadExcel}
                    />
                  </div>
                </div>
              </div>
           
              <div className="my-3 mx-2 p-2">
                <li className="my-2 ms-2 text-dark">Filter By Date</li>
                <div className="d-lg-flex justify-content-start align-items-end gap-2 flex-wrap">
                  <div>
                    <h6
                      className="text-secondary mb-2"
                      style={{ fontSize: "13px" }}
                    >
                      From Date
                    </h6>
                    <input
                      style={{
                        border: "1px solid grey",
                      }}
                      onChange={(e) => {
                        setfilterDates((prev) => ({
                          ...prev,
                          startDate: e.target.value,
                        }));
                      }}
                      value={filterDates.startDate}
                      type="date"
                      placeholder="YYYY-MM-DD"
                      className="form-control productiondate"
                    />
                  </div>
                  <div>
                    <h6
                      className="text-secondary mb-2 my-2"
                      style={{ fontSize: "13px" }}
                    >
                      To Date
                    </h6>
                    <input
                      style={{
                        border: "1px solid grey",
                      }}
                      onChange={(e) => {
                        setfilterDates((prev) => ({
                          ...prev,
                          endDate: e.target.value,
                        }));
                      }}
                      value={filterDates.endDate}
                      type="date"
                      placeholder="YYYY-MM-DD"
                      className="form-control productiondate"
                    />
                  </div>

                  <div className="d-flex text-center gap-1 justify-content-center mt-2">
                    <div className="">
                      <Mybutton
                        name={"Search"}
                        style={{ backgroundColor: "#7b7bff" }}
                        handleClick={handleSearch}
                      />
                    </div>

                    <div className="">
                      <Mybutton
                        backgroundColor={"#fb5e5e"}
                        name={"Reset Filter"}
                        handleClick={() => {
                          fetchTableData();
                          setfilterDates({
                            startDate: "",
                            endDate: "",
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="row mt-2">
              <div className="d-flex justify-content-end align-items-center px-2">
                <div className="col-3">
                  <div className="input-group input-group-sm">
                    <input
                      className="form-control  input-group-sm"
                      type="text"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setsearch(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* <div className=" p-2 table-container">
              <div className="card card_main">
                <div
                  className="card-body"
                  style={{
                    border: "1px solid grey",
                    borderRadius: "5px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "500px" }}
                  >
                    {filterdata.length > 0 ? (
                      <>
                        <table className="table table-bordered ">
                          <thead className="sticky-top bg-light">
                            <tr>
                              <th className="table_head text-dark">
                                PO Number
                              </th>
                              <th className="table_head text-dark">
                                Created Date
                              </th>
                              <th className="table_head text-dark">PO Date</th>
                              <th className="table_head text-dark">
                                Tax Invoice No
                              </th>
                              <th className="table_head text-dark">
                                Received Quantity
                              </th>
                              <th className="table_head text-dark">Location</th>
                              <th className="table_head text-dark">
                                PO Quantity
                              </th>
                              <th className="table_head text-dark">Unit</th>
                              <th className="table_head text-dark">
                                Component
                              </th>
                              <th className="table_head text-dark">Rate</th>
                              <th className="table_head text-dark">
                                Basic Rate
                              </th>
                              <th className="table_head text-dark">
                                Total GST(18%)
                              </th>
                              <th className="table_head text-dark">
                                Total Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterdata.map((item) => (
                              <tr className="table_row" key={item.id}>
                                <td>{item.newpo_number}</td>
                                <td>{item.newcreated_date}</td>
                                <td>{item.newpo_date}</td>
                                <td>{item.newtaxinvoiceNo}</td>
                                <td>{item.newreceived_quantity}</td>
                                <td>{item.newlocation}</td>
                                <td>{item.newpo_quantity}</td>
                                <td>{item.newunit}</td>
                                <td>{item.newcomponent}</td>
                                <td>{item.newrate}</td>
                                <td>{item.basic_rate}</td>
                                <td>{item.total_gst}</td>
                                <td>{item.total_ammount}</td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={3} className="text-right fw-bold">
                                Total:
                              </td>
                              <td></td>
                              <td className="fw-bold">
                                {totals.newreceived_quantity.toFixed(3)}
                              </td>

                              <td></td>
                              <td></td>
                              <td colSpan={2}></td>
                              <td className="fw-bold">
                                {totals.newrate.toFixed(3)}
                              </td>
                              <td className="fw-bold">
                                {totals.basic_rate.toFixed(3)}
                              </td>
                              <td className="fw-bold">
                                {totals.total_gst.toFixed(3)}
                              </td>
                              <td className="fw-bold">
                                {totals.total_amount.toFixed(3)}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        {totalPages > 0 ? (
                          <Pagination className="justify-content-center">
                            <Pagination.First
                              onClick={() => handlePageChange(1)}
                              disabled={currentPage === 1}
                            />
                            <Pagination.Prev
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                            />
                            {[...Array(totalPages)].map((_, index) => (
                              <Pagination.Item
                                key={index + 1}
                                active={index + 1 === currentPage}
                                onClick={() => handlePageChange(index + 1)}
                              >
                                {index + 1}
                              </Pagination.Item>
                            ))}
                            <Pagination.Next
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                            />
                            <Pagination.Last
                              onClick={() => handlePageChange(totalPages)}
                              disabled={currentPage === totalPages}
                            />
                          </Pagination>
                        ) : (
                          <div className="text-center text-danger">
                            <p>No Pages Found</p>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="d-flex justify-content-center ">
                        <p className="text-danger fw-bold">No Data Found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className=" p-2 table-container">
              <div className="card card_main">
                <div
                  className="card-body"
                  style={{
                    border: "1px solid grey",
                    borderRadius: "5px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "500px" }}
                  >
                    {podata.length > 0 && (
                      <table className="table">
                        <thead>
                          <tr>
                            <th>PO Number</th>
                            <th>PO Date</th>
                            <th>Tax Invoice</th>
                            <th>Received Quantity</th>
                            <th>Tax Invoice Quantity</th>
                            <th>Location</th>
                            <th>PO Quantity</th>
                            <th>Rate</th>
                            <th>Unit</th>
                            <th>Component</th>
                          </tr>
                        </thead>
                        <tbody>
                          {podata.map((item) => (
                            <tr key={item.id}>
                              <td>{item.po_number}</td>
                              <td>{item.po_date}</td>
                              <td>
                                <input
                                  type="number"
                                  value={item.taxinvoiceNo || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "taxinvoiceNo",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.received_quantity || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "received_quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.taxinvoice_quantity || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "taxinvoice_quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>

                              <td>{item.location}</td>
                              <td>{item.po_quantity}</td>
                              <td>{item.rate}</td>
                              <td>{item.unit}</td>
                              <td>{item.component}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10PX",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        textAlign: "center",
                        padding: "5px 12px",
                        fontSize: "16px",
                        borderRadius: "5px",
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="p-2 table-container">
              <div className="card card_main">
                <div
                  className="card-body"
                  style={{
                    border: "1px solid grey",
                    borderRadius: "5px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "500px" }}
                  >
                    {podata.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="table_head text-dark">PO Number</th>
                            <th className="table_head text-dark">PO Date</th>
                            <th className="table_head text-dark">
                              PO Quantity
                            </th>
                            <th className="table_head text-dark">
                              Tax Invoice
                            </th>
                            <th className="table_head text-dark">
                              Received Quantity
                            </th>
                            <th className="table_head text-dark">
                              Tax Invoice Quantity
                            </th>
                            <th className="table_head text-dark">Location</th>

                            <th className="table_head text-dark">Rate</th>
                            <th className="table_head text-dark">Unit</th>
                            <th className="table_head text-dark">Component</th>
                            <th className="table_head text-dark">
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {podata.map((item, index) => (
                            <tr key={item.id}>
                              <td>{item.po_number}</td>
                              <td>{item.po_date}</td>
                              <td>{item.po_quantity}</td>

                              <td>
                                <input
                                  type="text"
                                  value={item.taxinvoiceNo}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      "taxinvoiceNo",
                                      e.target.value,
                                      index
                                    )
                                  }
                                  disabled={
                                    activeRowIndex !== index &&
                                    activeRowIndex !== -1
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.received_quantity}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      // e,
                                      "received_quantity",
                                      e.target.value,
                                      index
                                    )
                                  }
                                  disabled={
                                    activeRowIndex !== index &&
                                    activeRowIndex !== -1
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  value={item.taxinvoice_quantity}
                                  onChange={(e) =>
                                    handleChange(
                                      item.id,
                                      // e,
                                      "taxinvoice_quantity",
                                      e.target.value,
                                      index
                                    )
                                  }
                                  disabled={
                                    activeRowIndex !== index &&
                                    activeRowIndex !== -1
                                  }
                                />
                              </td>

                              <td>{item.location}</td>
                              <td>{item.rate}</td>
                              <td>{item.unit}</td>
                              <td>{item.component.item_code}</td>
                              <td>{item.component.item}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p className="text-center text-danger fw-bold">
                        No data available
                      </p>
                    )}
                  </div>
                  {podata.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <button
                        style={{
                          textAlign: "center",
                          padding: "5px 12px",
                          fontSize: "16px",
                          borderRadius: "5px",
                          // backgroundColor: "#7b7bff",
                          // backgroundColor: "#a51523",
                          backgroundColor: "#51606e",
                          border: "none",
                        }}
                        onClick={handleSave}
                        disabled={activeRowIndex === -1}
                      >
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="p-2 table-container">
              <div className="card card_main">
                <div
                  className="card-body"
                  style={{
                    border: "1px solid grey",
                    borderRadius: "5px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "500px" }}
                  >
                    {
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th className="table_head text-dark">PO Number</th>

                            <th className="table_head text-dark">
                              PO Quantity
                            </th>
                            <th className="table_head text-dark">
                              Tax Invoice
                            </th>
                            <th className="table_head text-dark">
                              Received Quantity
                            </th>
                            <th className="table_head text-dark">
                              Tax Invoice Quantity
                            </th>
                            <th className="table_head text-dark">Location</th>

                            <th className="table_head text-dark">Rate</th>
                            <th className="table_head text-dark">Unit</th>
                            <th className="table_head text-dark">Component</th>
                            {/* <th className="table_head text-dark">Balance</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {data.length > 0 ? (
                            data.map((element) => (
                              <tr key={element.id}>
                                <td>{element.po_number}</td>

                                <td>{element.po_quantity}</td>

                                <td>{element.taxinvoiceNo}</td>
                                <td>{element.received_quantity}</td>
                                <td>{element.taxinvoice_quantity}</td>
                                <td>{element.location}</td>
                                <td>{element.rate}</td>
                                <td>{element.unit}</td>
                                <td>{element.component}</td>
                                {/* <td>
                                 {data.stock_log.balance != null
                                  ? data.stock_log.balance
                                  : "N/A"}
                                </td> */}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                className="text-center text-danger fw-bold"
                                colSpan={9}
                              >
                                No data submitted yet
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ComponentPo;
