import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

function HorizontalBarChart({
  labels,
  labelName,
  productionKGData,
  colors,
  heading,
  legendLabel,
  chartHeight,
}) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const data = {
    labels: labels,
    datasets: [
      {
        label: labelName,
        data: productionKGData,
        backgroundColor: colors,
        borderWidth: 1,
        // barThickness: 20,
      },
    ],
  };

  const options = {
    indexAxis: "x", // Horizontal bar chart
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      title: {
        display: true,
        text: heading,
        font: {
          size: 14,
        },
      },
    },
    responsive: true,
    // maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        ticks: {
          padding: 10,
          font: {
            size: 12,
          },
          autoSkip: false, // Ensure no labels are skipped
          maxRotation: 0, // Prevent rotation of labels if needed
          minRotation: 0,
        },
      },
    },
  };

  // Extract labels and colors for legend display
  const legendItems = labels.map((label, index) => (
    <div key={index} className="d-flex align-items-center mb-1 ">
      <div
        className="legend-color"
        style={{
          backgroundColor: colors[index % colors.length],
          width: "20px",
          height: "20px",
          marginRight: "8px",
          borderRadius: "4px",
          border: "1px solid black",
        }}
      ></div>
      <div>{label}</div>
    </div>
  ));

  return (
    <div>
      <div>
        <div className="col-12">
          <Bar data={data} options={options} height={chartHeight} />
        </div>
      </div>
      <div
        className="mt-3"
        style={{
          padding: "55px",
          textAlign: "center",
        }}
      >
        {/* <label className="my-2 fw-bold">{legendLabel}</label> */}
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          {/* {legendItems} */}
        </div>
      </div>
    </div>
  );
}

export default HorizontalBarChart;
