import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios, { Axios } from "axios";
import { BASE_URL } from "../../../Utils/constants";
import "../Ribbons/Ribbon.css";
import toast from "react-hot-toast";
import { ImSpinner5 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import Mybutton from "../../../Component/Mybutton/Mybutton";

function Ribbons() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    machine: {
      id: null,
      name: "Select Machine",
    },
    sku: {
      id: null,
      name: "Select SKU",
    },
    ribbons: "",
    // sku_id: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState([]);

  const [selectedSku, setselectedSKu] = useState("");
  const [ribbonData, setRibbonData] = useState([]);
  // console.log("ribbonData", ribbonData);

  const [loaderProd, setLoaderProd] = useState(true);
  const [validated, setValidated] = useState(null);

  const [plantData, setPlantData] = useState([]);
  const [selectedPlantId, setSelectedPlantId] = useState("");

  const [machineData, setMachineData] = useState([]);
  const [skuData, setSkuData] = useState([]);

  // URL
  const getskuDetails_url = BASE_URL + "api/GetRibbondata/"; //get & Post method
  const getPlantDetails_url = BASE_URL + "api/getplant/";
  const getMachineDetails_url = BASE_URL + "api/getplantsetup/";

  //plant data
  const getPlantDetails = async () => {
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getPlantDetails_url, { headers: header })
      .then((res) => {
        // console.log("plant data", res.data.data);
        if (res.data.status) {
          setPlantData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          // notify("Unathorized user", "error");
          toast("Unathorized user", "error");
          navigate("/");
        }
      });
  };

  useEffect(() => {
    getPlantDetails();
    // getProductionData();
  }, []);

  // get machine and sku Data
  const getData = async () => {
    setIsLoading(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    await axios
      .get(getMachineDetails_url, { headers: header })
      .then((res) => {
        if (res.data.status) {
          setState(res.data?.data);

          const key = "machineID";

          // Step 1: Create arrayUniqueByKey with unique items based on machine[key]
          let arrayUniqueByKey = [
            ...new Map(
              res.data?.data.map((item) => [item.machine[key], item])
            ).values(),
          ];

          // Step 2: Filter arrayUniqueByKey by selectedPlantId
          arrayUniqueByKey = arrayUniqueByKey.filter((plantMachine) => {
            return plantMachine?.machine?.plant?.plantID === selectedPlantId;
          });

          // Step 3: Update state with filtered array
          setMachineData(arrayUniqueByKey);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        if (err.response?.status === 401) {
          // notify("Unathorized user", "error");
          toast("Unathorized user", "error");
          navigate("/");
        }
      });
  };

  // handle sku select against machine id
  const handleSelect = (machine) => {
    setForm((prev) => ({
      ...prev,
      machine: {
        id: machine?.machine?.machineID,
        name: machine?.machine?.machine_name,
      },
    }));
    let id = machine?.machine?.machineID;

    let filteredState = state.filter((item) => {
      return item?.machine?.machineID === id;
    });

    let skuArr = [];
    for (let i = 0; i < filteredState.length; i++) {
      let newObj = {
        name: filteredState[i]?.sku?.product_name,
        id: filteredState[i]?.sku?.skuID,
      };
      skuArr.push(newObj);
    }
    setSkuData(skuArr);
  };

  useEffect(() => {
    if (selectedPlantId) {
      getData();
    }
  }, [selectedPlantId]);
  // Fetching SKU details

  const dropdownskuDetails = async () => {
    setLoaderProd(true);
    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .get(getskuDetails_url, { headers: header })
      .then((res) => {
        setLoaderProd(false);
        setRibbonData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    dropdownskuDetails();
  }, []);

  const handleSelectSku = (sku) => {
    setselectedSKu(sku?.InkID);
    setForm({
      ...form,
      sku_id: sku?.InkID,
    });
  };

  const handleSubmit = async (e) => {
    const f = e.currentTarget;
    e.stopPropagation();
    e.preventDefault();

    if (f.checkValidity() === false) {
      setValidated(true);
      // e.stopPropagation();
      return;
    }

    setValidated(false);

    let data = new FormData();
    data.append("ribbons", form.ribbons);
    data.append("sku_id", form.sku.id);
    data.append("plantID", selectedPlantId);

    let header = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    await axios
      .post(getskuDetails_url, data, { headers: header })
      .then((res) => {
        toast.success(res.data.messasge);
        dropdownskuDetails();
        setForm({
          ribbons: "",
          machine: {
            id: null,
            name: "Select Machine",
          },
          sku: {
            id: null,
            name: "Select SKU",
          },
        });
        setselectedSKu("");
        setSelectedPlantId("");
      })
      .catch((err) => {
        console.log(err);

        toast.error(err?.response?.data?.message);
        // if (err.response?.status === 401) {
        //   toast("Unathorized user", "error");
        //   navigate("/");
        // }
      });
  };

  return (
    <div>
      <div
        className="row p-2 m-4"
        style={{
          justifyContent: "center",
          textAlign: "center",
          margin: "auto",
          padding: "8px",
          gap: "15px",
          border: "1px solid grey",
          borderRadius: "5px",
          backgroundColor: "whitesmoke",

          boxShadow: `
      rgba(183, 188, 197, 0.14) 0px 2px 2px 0px,
      rgba(239, 239, 239, 0.1) 0px 3px 1px -2px,
      rgb(191, 192, 195, 0.94) 0px 1px 5px 0px
    `,
        }}
      >
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div
            className="row px-4 my-2"
            style={
              {
                // gap: "8px",
                // margin: "auto",
                // justifyContent: "center",
                // textAlign: "center",
                // style={{
                // }}
              }
            }
          >
            {/*  start*/}
            {/* <div className="d-flex"> */}
            <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
              <InputGroup>
                <DropdownButton
                  variant="outline-secondary"
                  title="Select Plant Id"
                >
                  {plantData.length
                    ? plantData.map((plant) => {
                        return (
                          <Dropdown.Item
                            key={plant?.plantID}
                            onClick={() => setSelectedPlantId(plant?.plantID)}
                          >
                            {plant?.plantID}
                          </Dropdown.Item>
                        );
                      })
                    : ""}
                </DropdownButton>
                <Form.Control
                  style={{ border: "1px solid grey" }}
                  readOnly
                  aria-label="Text input with dropdown button"
                  value={selectedPlantId ? selectedPlantId : "Select plantId"}
                />
              </InputGroup>
            </div>
            <div className="col-12 col-lg-6 col-md-4 px-4 my-2 machine">
              <InputGroup>
                <DropdownButton
                  disabled={isLoading}
                  variant="outline-secondary"
                  title="Select Machine"
                  className="custom-dropdown"
                >
                  {machineData.length
                    ? machineData.map((machine) => {
                        return (
                          <Dropdown.Item
                            key={machine?.machine?.machineID}
                            onClick={() => {
                              handleSelect(machine);
                              setForm((prev) => ({
                                ...prev,
                                sku: { name: "Select SKU", id: null },
                              }));
                            }}
                          >
                            {machine?.machine?.machine_name}
                          </Dropdown.Item>
                        );
                      })
                    : ""}
                </DropdownButton>
                <Form.Control
                  style={{ border: "1px solid grey" }}
                  readOnly
                  aria-label="Text input with dropdown button"
                  value={form.machine.name}
                />
              </InputGroup>
            </div>
            {/* </div> */}

            {/* <div className="d-flex"> */}
            <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
              <InputGroup>
                <DropdownButton
                  disabled={isLoading}
                  variant="outline-secondary"
                  title="SKU Name"
                >
                  {skuData.length ? (
                    skuData.map((sku) => {
                      return (
                        <Dropdown.Item
                          key={sku?.id}
                          onClick={() => {
                            setForm((prev) => ({ ...prev, sku: sku }));
                          }}
                        >
                          {sku?.name}
                        </Dropdown.Item>
                      );
                    })
                  ) : (
                    <Dropdown.Item
                      className="text-danger fw-medium"
                      style={{ fontSize: "13px" }}
                    >
                      Select machine first
                    </Dropdown.Item>
                  )}
                </DropdownButton>
                <Form.Control
                  style={{ border: "1px solid grey" }}
                  readOnly
                  aria-label="Text input with dropdown button"
                  value={form.sku.name}
                />
              </InputGroup>
            </div>

            <div className="col-12 col-lg-6 col-md-4 px-4 my-2">
              <Form.Control
                style={{ border: "1px solid grey" }}
                required
                value={form.ribbons}
                onChange={(e) =>
                  setForm({
                    ...form,
                    ribbons: e.target.value,
                  })
                }
                name="ribbons"
                type="text"
                placeholder="Add Ribbon rolls"
                className="form-control"
                aria-label="Weight"
                aria-describedby="basic-addon1"
                maxLength={10}
              />
            </div>
            {/* </div> */}
          </div>

          {/* sku name  */}
          {/* <div className="col-12 col-md-5 mb-3">
              <InputGroup required>
                <DropdownButton
                  required
                  className="form-control"
                  variant="outline-secondary"
                  title="Select sku"
                >
                  {ribbonData.length
                    ? ribbonData.map((item, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleSelectSku(item?.sku)}
                          >
                            {item?.sku?.InkID}
                          </Dropdown.Item>
                        );
                      })
                    : ""}
                </DropdownButton>
                <Form.Control
                  required
                  value={selectedSku ? selectedSku : ""}
                  name="ribbons"
                  type="text"
                  placeholder="Select SKU"
                  className="form-control"
                  aria-label="Weight"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </div> */}

          <div className="text-center p-2 mt-2">
            {/* <Button type="submit" color={"#7b7bff"}>
              Submit form
            </Button> */}
            <Mybutton backgroundColor="#51606e" name={"Submit"} type="submit" />
          </div>
        </Form>
      </div>
      <div className="row p-2 m-3">
        {loaderProd ? (
          <div className="p-2 table-container">
            <div className="card card_main">
              <div className="card-body ">
                <center>
                  <ImSpinner5 icon="spinner" className="spinner" />
                </center>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className=" table-container ">
              <div className="card card_main">
                <div
                  className="card-body"
                  style={{
                    border: "1px solid grey",
                    borderRadius: "5px",
                    backgroundColor: "whitesmoke",
                  }}
                >
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "500px" }}
                  >
                    <table className="table table-bordered">
                      <thead className="sticky-top bg-light">
                        <tr>
                          <th className="table_head text-dark">InkId</th>
                          <th className="table_head text-dark">Balance</th>
                          <th className="table_head text-dark">
                            single_print_length
                          </th>
                          <th className="table_head text-dark">
                            ribbon_length
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ribbonData.map((item) => {
                          return (
                            <tr>
                              <td>{item?.sku?.InkID}</td>
                              <td>{item?.balance}</td>
                              <td>{item?.single_print_length}</td>
                              <td>{item?.ribbon_length}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Ribbons;
