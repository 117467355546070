import React from "react";
import { Scatter } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const ScatterChart = ({ data }) => {
  // Processed data for scatter chart
  // const processedData = data.flatMap((machine) =>
  //   machine.plant_setups.map((setup) => ({
  //     x: machine.machine_name,
  //     y: setup.target,
  //     sku: setup.sku,
  //   }))
  // );

  // Chart data configuration
  // const chartData = {
  //   datasets: [
  //     {
  //       label: "Plant Setups",
  //       data: processedData,
  //       backgroundColor: "rgba(75, 192, 192, 0.6)",
  //       borderColor: "rgba(75, 192, 192, 1)",
  //       pointRadius: 5,
  //     },
  //   ],
  // };

  // Chart options configuration
  // const options = {
  //   scales: {
  //     x: {
  //       type: "category",
  //       labels: [...new Set(data.map((machine) => machine.machine_name))],
  //       title: {
  //         display: true,
  //         text: "Machines",
  //       },
  //     },
  //     y: {
  //       beginAtZero: true,
  //       title: {
  //         display: true,
  //         text: "Target",
  //       },
  //     },
  //   },
  //   plugins: {
  //     tooltip: {
  //       callbacks: {
  //         label: function (context) {
  //           let label = context.dataset.label || "";
  //           if (label) {
  //             label += ": ";
  //           }
  //           if (context.raw) {
  //             label += `SKU: ${context.raw.sku}, Target: ${context.raw.y}`;
  //           }
  //           return label;
  //         },
  //       },
  //     },
  //   },
  // };

  const machineData = data.filter((machine) => machine.plant_setups.length > 0);

  const skus = machineData.flatMap((machine) =>
    machine.plant_setups.map((setup) => setup.sku)
  );
  const uniqueSkus = [...new Set(skus)];
  const colors = [
    "rgba(255, 99, 132, 0.6)", // Red
    "rgba(54, 162, 235, 0.6)", // Blue
    "rgba(255, 206, 86, 0.6)", // Yellow
    "rgba(75, 192, 192, 0.6)", // Green
    "rgba(153, 102, 255, 0.6)", // Purple
    "rgba(255, 159, 64, 0.6)", // Orange
    "rgba(199, 199, 199, 0.6)", // Grey
    "rgba(83, 102, 255, 0.6)", // Dark Blue
    "rgba(255, 0, 255, 0.6)", // Magenta
    "rgba(0, 255, 255, 0.6)", // Cyan
  ];

  const chartData = {
    labels: machineData.map((machine) => machine.machine_name),
    datasets: uniqueSkus.map((sku, index) => ({
      label: sku,
      data: machineData.map((machine) => {
        const setup = machine.plant_setups.find((setup) => setup.sku === sku);
        return setup ? setup.target : 0;
      }),
      backgroundColor: colors[index % colors.length], // Assign color based on index
      borderColor: colors[index % colors.length].replace("0.6", "1"), // Adjust border color

      borderWidth: 1,
    })),
  };

  const options = {
    plugins: {
      legend: {
        //to show all colors in heading set true

        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <div className="scatter-chart-container border m-2 p-2 rounded shadow-sm  ">
      {/* <Scatter data={chartData} options={options} /> */}
      <label className="my-2 fw-bold">SKU Data</label>

      <Bar data={chartData} options={options} />
    </div>
  );
};

export default ScatterChart;
